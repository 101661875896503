<template>
  <div class="index">
    <div class="main">
      <div class="list">
        <van-row class="row title">
          <van-col span="3" justify="center" align="center" class="colum"
            >ID</van-col
          >
          <van-col span="5" justify="center" align="center" class="colum"
            >DATE</van-col
          >
          <van-col span="6" justify="center" align="center" class="colum"
            >STARTTIME</van-col
          >
          <van-col span="7" justify="center" align="center" class="colum"
            >CUSTOMER</van-col
          >
          <van-col span="3" justify="center" align="center" class="colum"
            >PHOTO</van-col
          >
        </van-row>
        <van-row class="row" v-for="item in list" :key="item.order_id">
          <van-col span="3" justify="center" align="center" class="colum">{{
            item.order_id
          }}</van-col>
          <van-col span="5" justify="center" align="center" class="colum">{{
            item.client_name
          }}</van-col>
          <van-col span="6" justify="center" align="center" class="colum">{{
            item.start_time
          }}</van-col>
          <van-col span="7" justify="center" align="center" class="colum"
            >{{ item.name }}
            <div class="view" style="margin-left: 8px">
              <van-icon name="orders-o" @click="copy(item)" /></div
          ></van-col>

          <van-col span="3" justify="center" align="center" class="colum"
            ><div class="view">
              <van-icon name="photo-o" @click="goPhoto(item)" /></div
          ></van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
import { getlist } from "@/api/start.js";
export default {
  name: "Home",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getData() {
      getlist().then((res) => {
        console.log(res);
        this.list = res.data;
      });
    },
    goPhoto(row) {
      console.log(11);
      this.$router.push({ path: "./photo", query: { id: row.id } });
    },
    copy(row) {
      console.log(row);
      row.dayLight = row.day.split("/")[2];
      let obj = {
        id: row.order_id,
        user_id: row.id,
        name: row.name,
        complete_status: row.complete_status,
        datatime: row.datatime,
        day: row.day,
        type: row.type,
        gender: row.gender,
        time_length: row.time_length,
        dayLight: row.dayLight,
        client_id: row.client_id,
        client_name: row.client_name,
      };
      if (row.type == 1 || row.type == 3) {
        let url = `http://www.massageform.com.au/html/page/physician.html?orderInfo=${JSON.stringify(
          obj
        )}`;
        this.$copyText(url).then(() => {
          Toast("success");
        });
      } else {
        let url = `http://www.massageform.com.au/html/page/physician2.html?orderInfo=${JSON.stringify(
          obj
        )}`;
        this.$copyText(url).then(() => {
          // Toast("提示内容");
        });
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.index {
  background: #f6f6f6;
  height: 100vh;
}
.main {
  height: 100%;
  overflow-y: auto;
  padding: 8px;
}
.list {
  padding: 8px;
  background: #fff;
  border-radius: 8px;
}
.row {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.title {
  background: #c1c1c1;
}
.colum {
  padding: 16px 0;
  font-size: 10px;
  border-left: 0.5px solid #000000;
  border-right: 0.5px solid #000000;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view {
  background: #087536;
  display: inline;
  font-size: 10px;
  padding: 4px;
  color: #fff;
  border-radius: 4px;
}
</style>
